// Rich text
import "trix"
import "@rails/actiontext"

require("turbolinks").start()

// Make Rails available globally
const Rails = require("@rails/ujs")
global.Rails = Rails
Rails.start()

// Load Bootstrap and Popper.js
require("@popperjs/core")
// make Bootstrap available globally
const bootstrap = require("bootstrap")
global.bootstrap = bootstrap

import("controllers")

Rails.$ = function (selector, container = document) {
  return container.querySelector(selector)
}

Rails.$$ = function (selector, container = document) {
  return new Array(...container.querySelectorAll(selector))
}

Rails.remove_elements = function (selector, container = document) {
  Rails.$$(selector, container).forEach((element) =>
    element.parentNode.removeChild(element)
  )
}

Rails.replace = function (selector, contents) {
  Rails.$$(selector).forEach(function (el) {
    el.outerHTML = contents
  })
}

Rails.yellow_fade = function (selector) {
  Rails.$$(selector).forEach(function (element) {
    element.classList.add("yellow-fade")
    setTimeout(() => element.classList.remove("yellow-fade"), 1000)
  })
}

Rails.sort = function (selector) {
  Rails.$$(selector).forEach(function (el) {
    Array.from(el.children)
      .sort((a, b) => {
        return a.dataset.sort.localeCompare(b.dataset.sort)
      })
      .forEach((e) => el.appendChild(e))
  })
}
